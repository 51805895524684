import React from "react";
import config from "../../config";
import { DeleteOutlined } from "@ant-design/icons";
import "./css/CollageComponent.css";
const CollageComponent = ({ count, imageUrl, Delete, item }) => {
  return (
    <div className="CollageComponentMain">
      {Delete && (
        <div className="CollageComponentHeding">
          {" "}
          <h1>Collage {count} </h1>{" "}
          <DeleteOutlined className="icon" onClick={Delete} />
        </div>
      )}
      <div className="CollageComponentimg">
        <img src={`${config.imageUrl}${imageUrl}`} />
      </div>
      <div className=""  style={{marginLeft:'10px'}}>
        <p><span style={{fontWeight:'bold'}}>Description : </span> {item.COLLAGE_DESCRIPTION}</p>
      </div>
    </div>
  );
};

export default CollageComponent;
