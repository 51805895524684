import React from "react";
import "./css/userTabs.css";

function UserTabs({ topImageSrc, text, backgroundColor, onClick,boxShadow }) {
  return (
    <div
      className="user-tabs-container"
      style={{ "--background-color": backgroundColor ,boxShadow: boxShadow===text.replace(/\s+/g, "")?"0px 1px 3px 4px #dbd0d0":"none"}}
      onClick={() => onClick("Component clicked")}
    >
      <div className="img">
        <img
          src={topImageSrc}
          alt="Top"
          className="user-tabs-image user-tabs-top-image"
        />
        
      <div className="user-tabs-text">{text}</div>
      </div>

    </div>
  );
}

export default UserTabs;
