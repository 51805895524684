import React, { useState, useEffect } from "react";
import UserDisplayCard from "../cards/UserDisplayCard";
import "./css/New.css";
import { DatePicker, Button, Typography, Row, Col, Spin, message } from "antd";
import axios from "axios";
import config from "../../config";
import Popup from "../cards/Popup";
import Assigned from "./Assigned";
import { notification, Space } from "antd";

function New({ AssignedSylzData }) {
  const [shoptype, setShopType] = useState("Shopping");
  const [showStylz, setShowStylz] = useState(false);
  const [UseData, setUserData] = useState([]);
  const [SelectUserData, setSelectUserData] = useState([]);
  const [StylzList, setStylzList] = useState([]);
  const [AssignedUser, setAssignedUser] = useState([]);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));

  const [loading, setloading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const fcmToken = sessionStorage.getItem("fcmToken");

  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: "Notification Title",
      description: msg,
      duration: 10,
    });
  };
  const getUnAssignedDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}getUnAssignedDetails/${userId}`
      );

      // Handle the response as needed
      console.log(response.data);
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        // const newFilteredData = data.filter((item) => {
        //   return item?.service_category?.split(" ").pop() === shoptype;
        // });
        console.log(data, "......");

        setUserData(data);
        setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };

  const getStylistList = async () => {
    try {
      const response = await axios.get(`${config.baseURL}getStylistList`);

      // Handle the response as needed
      console.log(response.data, "....");
      if (response?.data?.recordset) {
        console.log("....");
        setStylzList(response?.data?.recordset);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  const getCustomerDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}getCustomerDetails/${userId}`
      );

      // Handle the response as needed
      console.log(response.data);
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        // const newFilteredData = data.filter(item => {
        //   return item?.service_category?.split(' ').pop() === shoptype;
        // });
        setAssignedUser(data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      // throw error;
    }
  };
  useEffect(() => {
    // console.log(LoginUser, "LoginUser");
    getUnAssignedDetails(LoginUser?.USER_ID);
  }, []);

  useEffect(() => {
    getStylistList();
  }, []);

  const onClick = (data) => {
    console.log(data, "Onclick data ....");
    setSelectUserData(data);
    if (data) {
      setShowStylz(true);
    }
  };

  const AssignedStylz = async (data) => {
    console.log(
      data,
      "sidjshisil....",
      SelectUserData?.user_id,
      SelectUserData?.user_service_id,
      data?.USER_ID,
      "dkmdsks",
      SelectUserData
    );
    try {
      const response = await axios.post(
        `${config.baseURL}stylistAssign`,
        new URLSearchParams({
          STYLIST_ID: data?.USER_ID.toString(),
          USER_ID: SelectUserData?.user_id.toString(),
          STATUS: "1",
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.data?.message === "success") {
        console.log(response.data);
        await AssignedStylzNotifytouser(data);

        // openNotificationWithIcon("success", "Assigned successfully. ");
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      throw error;
    }
  };
  const handleStyleClick = async (data) => {
    console.log(data, "Onclick data ....");
    await AssignedStylz(data);
  };
  const AssignedStylzNotifytouser = async (data) => {
    console.log(data, "sidjshisil....");
    await AssignedStylzNotifytoBackend(data);
    AssignedSylzData(SelectUserData);
    await getCustomerDetails(LoginUser?.USER_ID);
    console.log(SelectUserData.fcm_token, "SelectUserData.fcm_token....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData?.fcm_token,
          title: "Skanda Admin",
          body: "A stylist has been assigned to you",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data, "AssignedStylzNotifytouser ");
        await AssignedStylzNotifytostylist(data);

        // openNotificationWithIcon('success',"Assigned successfully. ")
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const AssignedStylzNotifytostylist = async (data) => {
    console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: data.FCM_TOKEN,
          title: "Skanda Admin",
          body: "A stylist has been assigned to you",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
        // await AssignedStylzNotifytoBackend(data);
        openNotificationWithIcon("success", "Assigned successfully. ");
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  const AssignedStylzNotifytoBackend = async (data) => {
    console.log(data, "sidjshisil....");

    try {
      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Skanda Admin",
          NOTI_BODY: "A stylist has been assigned to you",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: data?.STYLIST_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_ID.toString(),
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        console.log(response.data);
        getUnAssignedDetails(LoginUser?.USER_ID);
        openNotificationWithIcon("success", "Assigned successfully. ");
        setTimeout(() => {}, 10000);
        setShowStylz(!showStylz);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  return (
    <div className="heading-container">
      <h1 className="heading">Customer Information Ready for Service</h1>
      <div className="body-section">
        {!loading ? (
          <>
            {UseData.map((user) => (
              <UserDisplayCard
                key={user.user_id}
                image={user.user_photo}
                name={user.first_name}
                purchasedate={user.start_date}
                category={user.service_category}
                dob={user.age}
                background={`${
                  user?.service_category === "Digital Wardrobe"
                    ? "#FBF1E9"
                    : "#ffffbc"
                }`}
                buttonText={"Assign"}
                onClick={() => onClick(user)}
              />
            ))}
          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
        {showStylz && StylzList && (
          <Popup
            namesList={StylzList}
            onStylzClick={(data) => {
              handleStyleClick(data);

              // setShowStylz(!showStylz);
            }}
            onClose={(data) => {
              setShowStylz(!showStylz);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default New;
