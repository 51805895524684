// import React, { useState, useEffect } from "react";
// import "./css/UserProfile.css"; // Import the CSS file for styling
// import UserTabs from "../Components/cards/UserTabs";
// import config from "../config";
// import Fileinfo from "../images/Usertabs/file-info-paper-person-profile.svg";
// import Searchsort from "../images/Usertabs/searchsort.svg";
// import Question from "../images/Usertabs/question-answer-help.svg";
// import Collage from "../images/Usertabs/collage.svg";

// //tabs
// import UserQuestions from "../Components/Userprofile/UserQuestions";
// import StyleSearch from "../Components/Userprofile/StyleSearch";
// import Guidelines from "../Components/Userprofile/Guidelines";
// import Scrapinglinks from "../Components/Userprofile/Scrapinglinks";

// import Profile from '../images/profile.jpg'

// const UserProfile = ({ onButtonClick, setBack, SelectUserData }) => {
//   console.log(SelectUserData,"SelectUserData......mmmmm")
//   const [seletedTab, setSelectedTab] = useState("UserQuestions");
//   const tabsData = [
//     {
//       id: 1,
//       topImageSrc: Question,
//       bottomImageSrc: "https://via.placeholder.com/100",
//       text: "User Questions",
//     },
//     {
//       id: 2,
//       topImageSrc: Fileinfo,
//       bottomImageSrc: "https://via.placeholder.com/100",
//       text: "Guidelines",
//     },
//     {
//       id: 3,
//       topImageSrc: Searchsort,
//       bottomImageSrc: "https://via.placeholder.com/100",
//       text: "Style Search",
//     },
//     {
//       id: 4,
//       topImageSrc: Collage,
//       bottomImageSrc: "https://via.placeholder.com/100",
//       text: "Scraping links",
//     },
//   ];

//   const Type= SelectUserData?.service_category==="Online Shopping"? "Shopping":"Wardrobe";

//   useEffect(() => {}, [seletedTab,SelectUserData]);

//   return (
//     <div className="userProfileMain">
//       <div className="top">
//         <h1 className="heading">{Type} Assistant Service</h1>
//         <button onClick={() => setBack("false")}>Back</button>
//       </div>
//       <div className="userProfielBody">
//         <div className="user-profile">
//           <div className="profile-info">
//             <img
//               src={`${SelectUserData.user_photo ? `${config.imageUrl}${SelectUserData.user_photo}` : Profile}`}
//               alt="User"
//               className="profile-img"
//             />
//             <p className="profile-name">{SelectUserData?.first_name}</p>
//             <p className="profile-dob">{SelectUserData?.age}</p>
//             <p className="profile-height"> {SelectUserData?.height}</p>
//           </div>
//           <button className="profile-button" onClick={onButtonClick}>
//             Close Service
//           </button>
//         </div>
//         <div className="UserTabs">
//           {tabsData.map((tab) => (
//             <UserTabs
//               key={tab.id}
//               topImageSrc={tab.topImageSrc}
//               bottomImageSrc={tab.bottomImageSrc}
//               text={tab.text}
//               backgroundColor="white"
//               boxShadow={seletedTab}
//               onClick={() => {
//                 setSelectedTab(tab.text.replace(/\s+/g, ""));

//                 console.log(tab.text.replace(/\s+/g, ""));
//               }}
//             />
//           ))}
//         </div>
//       </div>
//       <div className="userProfileBody2">
//         {/* {seletedTab && (
//           <div>
//             <seletedTab />
//             {console.log(seletedTab, "seletedTab......")}
//           </div>
//         )} */}

//         {seletedTab === "UserQuestions" && <UserQuestions SelectUserData={SelectUserData}/>}
//         {seletedTab === "StyleSearch" && <StyleSearch  SelectUserData={SelectUserData}/>}
//         {seletedTab === "Guidelines" && <Guidelines SelectUserData={SelectUserData}/>}
//         {seletedTab === "Scrapinglinks" && <Scrapinglinks SelectUserData={SelectUserData}/>}
//       </div>
//     </div>
//   );
// };

// export default UserProfile;

import React, { useState, useEffect } from "react";
import "./css/UserProfile.css"; // Import the CSS file for styling
import UserTabs from "../Components/cards/UserTabs";
import config from "../config";
import Fileinfo from "../images/Usertabs/file-info-paper-person-profile.svg";
import Searchsort from "../images/Usertabs/searchsort.svg";
import Question from "../images/Usertabs/question-answer-help.svg";
import Collage from "../images/Usertabs/collage.svg";

//tabs
import UserQuestions from "../Components/Userprofile/UserQuestions";
import StyleSearch from "../Components/Userprofile/StyleSearch";
import Guidelines from "../Components/Userprofile/Guidelines";
import Scrapinglinks from "../Components/Userprofile/Scrapinglinks";
import Accessories from "./Accessories";
import Wardrobe from "./Wardrobe";

import axios from "axios";

import Profile from "../images/profile.jpg";
import Chatbot from "../Components/chatboat/chat";
import { Drawer, Button, Input, Upload, message, Modal, Flex } from "antd";

import { useNavigate } from "react-router-dom";
import {
  MessageOutlined,
  UploadOutlined,
  CameraOutlined,
  SendOutlined,
} from "@ant-design/icons";
import CollageBody from "./CollageBody";
import Footwear from "./Footwear";

const UserProfile = ({
  onButtonClick,
  setBack,
  SelectUserData,
  CloseRecord,
  servicetype,
}) => {
  const [displaychat, setdisplaychat] = useState(false);

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    CloseRecord(SelectUserData);
    // console.log("Confirmed");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showDrawer = () => {
    setdisplaychat(true);
  };

  console.log(SelectUserData, "SelectUserData......mmmmm");
  const [seletedTab, setSelectedTab] = useState("UserQuestions");
  const Type =
    SelectUserData?.service_category === "Online Shopping"
      ? "Shopping"
      : "Wardrobe";

  // const formatDate = (dateString) => {
  //   if (!dateString) return ""; // Handle case where dateString is null or undefined

  //   // Handle various date input formats by trying to parse it first
  //   const parsedDate = new Date(Date.parse(dateString));

  //   // Check if the parsed date is valid
  //   if (isNaN(parsedDate.getTime())) return "Invalid Date";

  //   // Array of month abbreviations
  //   const monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   const day = parsedDate.getDate();
  //   const month = monthNames[parsedDate.getMonth()]; // Get the 3-letter month abbreviation
  //   const year = parsedDate.getFullYear();

  //   // Format the date as Day 3-Letter Month, Year
  //   return `${day < 10 ? "0" : ""}${day} ${month}, ${year}`;
  // };
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle 00 hour as 12 AM

    return `${day} ${month}, ${year} ${hours}:${minutes}${ampm}`;
  }
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }
  const tabsData = [
    {
      id: 1,
      topImageSrc: Question,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "User Questions",
    },
    {
      id: 2,
      topImageSrc: Fileinfo,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Color Analysis",
    },
    {
      id: 3,
      topImageSrc: Searchsort,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Style Search",
    },
    {
      id: 4,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Wardrobe",
    },
    {
      id: 5,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Accessories",
    },
    {
      id: 6,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Foot Wear",
    },
    {
      id: 7,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Collage",
    },
  ];
  const tabsDataWardrobe = [
    {
      id: 1,
      topImageSrc: Question,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "User Questions",
    },
    {
      id: 2,
      topImageSrc: Fileinfo,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Color Analysis",
    },
    // {
    //   id: 3,
    //   topImageSrc: Searchsort,
    //   bottomImageSrc: "https://via.placeholder.com/100",
    //   text: "Style Search",
    // },
    {
      id: 4,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Wardrobe",
    },
    {
      id: 5,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Accessories",
    },
    {
      id: 6,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Foot Wear",
    },
    {
      id: 7,
      topImageSrc: Collage,
      bottomImageSrc: "https://via.placeholder.com/100",
      text: "Collage",
    },
  ];
  // text: SelectUserData?.service_category === "Online Shopping" ?"Scraping links":"Wardrobe",

  useEffect(() => {}, [seletedTab, SelectUserData, displaychat]);

  function parseDateString(dateString) {
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      const [day, month, year] = dateString.split("/");
      return new Date(`${year}-${month}-${day}`);
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      const [month, day, year] = dateString.split("/");
      return new Date(`${year}-${month}-${day}`);
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return new Date(dateString);
    }

    throw new Error("Invalid date format");
  }

  function calculateAge(dobString) {
    try {
      const birthDate = parseDateString(dobString);
      const today = new Date();

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  }
  return (
    <div className="userProfileMain">
      <div className="top">
        <h1 className="heading">{Type} Assistant Service</h1>
        <button className="backButton" onClick={() => setBack("false")}>
          Back
        </button>
      </div>
      <div className="userProfielBody">
        <div className="user-profile">
          <div className="profile-info">
            <div>
              <img
                src={`${
                  SelectUserData.user_photo
                    ? extractBaseUrl(
                        `${config.imageUrl}${SelectUserData.user_photo}`
                      )
                    : Profile
                }`}
                alt="User"
                className="profile-img"
              />
            </div>
            <Flex gap={"18% "}>
              <Flex vertical gap={20}>
                <p className="profile-name">
                  <span className="Profile-header">Name:</span>
                  <span className="Profile-subheader">
                    {/* {SelectUserData?.first_name  } */}

                    {SelectUserData?.first_name
                      ? SelectUserData?.first_name
                      : "No Name"}
                  </span>
                </p>

                <p className="profile-dob">
                  <span className="Profile-header"> Age: </span>
                  <span className="Profile-subheader">
                    {" "}
                    {SelectUserData?.age
                      ? calculateAge(SelectUserData?.age)
                      : SelectUserData?.AGE
                      ? SelectUserData?.AGE
                      : ""}
                  </span>
                </p>

                <p className="profile-height">
                  <span className="Profile-header">Height: </span>
                  <span className="Profile-subheader">
                    {SelectUserData?.height} Feet{" "}
                    {SelectUserData?.height_inchs
                      ? `${SelectUserData?.height_inchs} Inches`
                      : ""}
                  </span>
                </p>
              </Flex>

              <Flex vertical gap={20}>
                <p className="profile-height">
                  <span className="Profile-header"> Gender: </span>
                  <span className="Profile-subheader">
                    {" "}
                    {SelectUserData?.gender}
                  </span>
                </p>

                <p className="profile-height">
                  <span className="Profile-header">Purchased Date: </span>
                  <span className="Profile-subheader">
                    {formatDate(SelectUserData?.start_date)}
                  </span>
                </p>
                <p className="profile-height">
                  <span className="Profile-header">
                    {SelectUserData?.TOP_SIZE ? "Size :" : ""}
                  </span>
                  <span className="Profile-subheader">
                    {SelectUserData?.TOP_SIZE ? "Top  - " : ""}{" "}
                    {SelectUserData?.TOP_SIZE
                      ? `${SelectUserData?.TOP_SIZE},`
                      : "  "}{" "}
                    {SelectUserData?.BOTTOM_SIZE ? "Bottom - " : ""}{" "}
                    {SelectUserData?.BOTTOM_SIZE}
                  </span>
                </p>
              </Flex>
            </Flex>
            {/* <Chatbot SelectUserData={SelectUserData}
            /> */}
            {servicetype === "view" ? (
              <></>
            ) : (
              <Button
                // type="primary"
                style={{ border: "none", background: "none" }}
                className="buttons"
                icon={<MessageOutlined style={{ fontSize: "28px" }} />}
                onClick={() => setdisplaychat(!displaychat)}
              ></Button>
            )}
            {displaychat && (
              <Chatbot
                SelectUserData={SelectUserData}
                onClosebutton={(data) => {
                  console.log("dnindf");
                  setdisplaychat(!displaychat);
                }}
              />
            )}
          </div>
          {servicetype === "view" ? (
            <></>
          ) : (
            <button
              className="button"
              onClick={
                () => showModal()
                // CloseRecord(SelectUserData)
              }
            >
              Close Service
            </button>
          )}
        </div>
        <hr className="boder" />
        <div className="UserTabs">
          {(SelectUserData.service_category === "Online Shopping"
            ? tabsData
            : tabsDataWardrobe
          ).map((tab) => (
            <UserTabs
              key={tab.id}
              topImageSrc={tab.topImageSrc}
              bottomImageSrc={tab.bottomImageSrc}
              text={tab.text}
              backgroundColor="white"
              boxShadow={seletedTab}
              onClick={() => {
                setSelectedTab(tab.text.replace(/\s+/g, ""));

                console.log(tab.text.replace(/\s+/g, ""));
              }}
            />
          ))}
        </div>
      </div>

      <div className="userProfileBody2">
        {/* {seletedTab && (
          <div>
            <seletedTab />
            {console.log(seletedTab, "seletedTab......")}
          </div>
        )} */}

        {seletedTab === "UserQuestions" && (
          <UserQuestions SelectUserData={SelectUserData} />
        )}
        {seletedTab === "StyleSearch" && (
          <StyleSearch SelectUserData={SelectUserData} />
        )}
        {seletedTab === "ColorAnalysis" && (
          <Guidelines SelectUserData={SelectUserData} />
        )}
        {seletedTab === "Scrapinglinks" && (
          <Scrapinglinks SelectUserData={SelectUserData} />
        )}
        {seletedTab === "Accessories" && (
          <Accessories SelectUserData={SelectUserData} />
        )}
        {seletedTab === "Wardrobe" && (
          <Wardrobe SelectUserData={SelectUserData} type={"display"} />
        )}
        {seletedTab === "Collage" && (
          <CollageBody SelectUserData={SelectUserData} />
        )}
        {seletedTab === "FootWear" && (
          <Footwear SelectUserData={SelectUserData} />
        )}
      </div>

      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <button
            key="cancel"
            onClick={handleCancel}
            className="button"
            style={{ marginRight: "7px" }}
          >
            Cancel
          </button>,
          <button
            key="confirm"
            type="primary"
            onClick={handleOk}
            className="button"
          >
            Confirm
          </button>,
        ]}
        centered
      >
        <p>Would you like to close the user service?</p>
      </Modal>
    </div>
  );
};

export default UserProfile;
