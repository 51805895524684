import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Flex } from "antd";
import axios from "axios";
import config from "../../config";
import "./Notification.css";
function Notification({ SelectUserData }) {
  const [loading, setloading] = useState(true);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  const getUserData = async (userId) => {
    const endpointUrl =
      LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";

    try {
      const response = await axios.get(`${config.baseURL}${endpointUrl}/`);

      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        setUserData(data);
        setloading(false);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  function formatDateToDDMMYY(isoString) {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  }

  return (
    <div className="userProfileMain">
      <h1 className="heading">Notification</h1>
      <div className="body-section">
        {!loading ? (
          <>
            <Row gutter={[16, 16]}>
              {UseData.length > 0 ? (
                <>
                  {UseData.map((user) => (
                    <Col xs={24} sm={12} md={12} lg={8} key={user.NOTI_ID}>
                      <div className="notify">
                        <Flex gap={30} vertical className="notifybody">
                          <div className="notiTitle-Div">
                            <div className="notiTitle">{user.NOTI_TITLE}</div>
                          </div>
                          <Flex vertical gap={20} className="Notisub-Div">
                            <div>
                              <p>{user.NOTI_BODY}</p>
                            </div>
                            <Flex
                              vertical
                              gap={20}
                              className="Notification-Content-Div"
                            >
                              <div className="Notification-Content">
                                <p>Date :</p>
                                <div>
                                  {formatDateToDDMMYY(user.NOTI_SENT_DATE)}
                                </div>
                              </div>
                              <div className="Notification-Content">
                                <p>Service_Name :</p>
                                <div>
                                  {user.SERVICE_ID === "1"
                                    ? "Shopping"
                                    : user.SERVICE_ID === "2"
                                    ? "Wardrobe"
                                    : user.SERVICE_ID}
                                </div>
                              </div>
                              {/* <div className="Notification-Content">
                            <p>Service_Id :</p>
                            <div>{user.SERVICE_ID}</div>
                          </div>
                          <div className="Notification-Content">
                            <p>User_Service_Id :</p>
                            <div>{user.USER_SERVICE_ID}</div>
                          </div> */}
                            </Flex>
                          </Flex>
                        </Flex>
                      </div>
                    </Col>
                  ))}
                </>
              ) : (
                <div className="nousernoti">
                  
                  <h3> No user Notifications</h3>
                </div>
              )}
            </Row>
          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Notification;
