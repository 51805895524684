import React, { useState } from "react";
import "./css/Popup.css";

const Popup = ({ namesList, onClose, onStylzClick }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleNameClick = (item) => {
    setSelectedItem(item);
    setShowConfirmation(true);
  };

  const handleConfirmClick = () => {
    onStylzClick(selectedItem);
    setShowConfirmation(false);
    onClose();
  };

  const handleCancelClick = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="closebutton">
          <h2>Assigned To</h2>
          <div className="buttondiv">
            <button
              className="button"
              onClick={onClose}
              style={{ margin: "0" }}
            >
              &times; {/* Unicode character for "X" */}
            </button>
          </div>
        </div>

        {!showConfirmation ? (
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "68%",
            }}
          >
            <ul>
              {namesList.map((item) => (
                <li key={item.id} onClick={() => handleNameClick(item)}>
                  {item.FIRST_NAME}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="confirmation-popup">
            <p>Would you assign to {selectedItem?.FIRST_NAME}?</p>
            <div className="confirmation-buttons">
              <button
                onClick={handleCancelClick}
                className="button extrabutton"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmClick}
                className="button extrabutton"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
