import React, { useState, useEffect } from "react";
import UserWardrobe from "../Components/cards/UserWardrobe";
import axios from "axios";
import config from "../config";

const Wardrobe = ({ SelectUserData, type }) => {
  const [UserImagesData, setUserImagesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserImagesData = async () => {
    if (!SelectUserData?.user_id || !SelectUserData?.gender) return;

    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${config.baseURL}getImages/${SelectUserData?.user_id}/${SelectUserData?.gender}`
      );

      if (response?.data?.recordset) {
        const data = response?.data?.recordset;
        setUserImagesData(data);
      }
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch data when the component mounts or SelectUserData changes
  useEffect(() => {
    getUserImagesData();
  }, [SelectUserData]);

  return (
    <div>
      {type === "display" && (
        <>
        <div className="userProfileMain">
          <h1 className="heading"> Wardrobe</h1>
        </div>
        <p>This is user's wardrobe:</p>
        </>
      )}
      {loading ? (
        <div>Loading...</div> // Display a loading message or spinner
      ) : (
        <UserWardrobe UserWardrobeImagesData={UserImagesData} />
      )}
    </div>
  );
};

export default Wardrobe;
