import React from "react";
import "./css/UserDisplayCard.css";
import config from "../../config";
import Profile from "../../images/profile.jpg";
const CurrentServicesCard = ({
  image,
  name,
  dob,
  purchasedate,
  gender,
  category,
  buttonText,
  background,
  onClick,
  user,
}) => {
  function extractBaseUrl(url) {
    const parts = url.split("https://").filter(Boolean);

    return `https://${parts[parts.length - 1]}`;
  }

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "Invalid Date";

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()]; // Get the 3-letter month abbreviation
    const year = date.getFullYear();

    return `${day < 10 ? "0" : ""}${day} ${month}, ${year}`;
  };

  return (
    <>
      <div
        className="user-display-card"
        style={{ background: `${background}` }}
      >
        {/* <div className='User-Disaplay-Card-Container-Header'><p>hi</p></div> */}
        <div className="first-div">
          <div className="image-container">
            <img
              src={`${
                image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile
              }`}
              alt="User"
            />
          </div>
          <div className="info-container">
            <div className="info-item-container">
              <div className="info-item">
                {name ? name : "No Name"}
                <div className="info-itemdob">
                  <div>Purchased Date:</div>
                  <div className="User-Purc-Date-Div">
                    {purchasedate
                      ? formatDate(purchasedate)
                      : user?.enrollment_date
                      ? formatDate(user?.enrollment_date)
                      : ""}
                  </div>
                </div>
              </div>
              <div className="info-itemdob-gender">
                <div>Gender :</div>
                <div className="User-Purc-Date-Div">{gender}</div>
              </div>
              <div>{category}</div>
            </div>
            <div className="button-container">
              <button onClick={onClick}>{buttonText}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentServicesCard;
