// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import config from "../config";
// import "./css/Accessories.css";

// const Accessories = ({ SelectUserData }) => {
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
//   const fcmToken = sessionStorage.getItem("fcmToken");

//   // State to store accessories data, selected category, selected subcategory, and selected items
//   const [AccessoriesData, setAccessoriesData] = useState([]);
//   const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [selectedItems, setSelectedItems] = useState({});

//   // Fetch user accessories data
//   const getUserAccessoriesData = async () => {
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getStylistCollages/${"Female"}`
//       );

//       if (response?.data?.recordset) {
//         const fetchedData = response.data.recordset;
//         setAccessoriesData(fetchedData);
//         console.log(response);

//         // Automatically select the first category and subcategory
//         const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
//         const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
//         setSelectedOutfitCategory(firstCategory);
//         setSelectedSubcategory(firstSubcategory);
//       }
//     } catch (error) {
//       console.error("Error fetching accessories data:", error);
//     }
//   };

//   useEffect(() => {
//     getUserAccessoriesData();
//   }, []);

//   // Handle category selection
//   const handleCategorySelect = (category) => {
//     setSelectedOutfitCategory(category);

//     // Reset selected items and subcategory when category changes
//     const firstSubcategoryInCategory = AccessoriesData.find(
//       (item) => item.OUTFIT_CATEGORY === category
//     )?.OUTFIT_TEXT;
//     setSelectedSubcategory(firstSubcategoryInCategory);
//     setSelectedItems({});
//   };

//   // Handle subcategory (OUTFIT_TEXT) selection
//   const handleSubcategorySelect = (subcategory) => {
//     setSelectedSubcategory(subcategory);
//   };

//   // Handle item selection
//   const handleItemSelect = (category, item) => {
//     setSelectedItems((prevState) => ({
//       ...prevState,
//       [category]: item,
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = () => {
//     console.log("Selected Items:", selectedItems);
//   };

//   // Filter data based on the selected category and subcategory
//   const filteredData = AccessoriesData.filter(
//     (item) =>
//       item.OUTFIT_CATEGORY === selectedOutfitCategory &&
//       item.OUTFIT_TEXT === selectedSubcategory
//   );

//   // Extract unique categories and subcategories
//   const uniqueCategories = [
//     ...new Set(AccessoriesData.map((item) => item.OUTFIT_CATEGORY)),
//   ];

//   const subcategoriesInSelectedCategory = [
//     ...new Set(
//       AccessoriesData.filter(
//         (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
//       ).map((item) => item.OUTFIT_TEXT)
//     ),
//   ];

//   return (
//     <>
//       {" "}
//       <div className="userProfileMain">
//         <h1 className="heading ">Accessories </h1>
//       </div>
//       <div className="accessories-container">
//         {/* Dynamic Category Tabs */}

//         <div className="user-wardrobe scroll">
//           {uniqueCategories.map((category, index) => (
//             <button
//               key={index}
//               className={`button ${
//                 selectedOutfitCategory === category ? "selected" : ""
//               }`}
//               onClick={() => handleCategorySelect(category)}
//             >
//               {category}
//             </button>
//           ))}
//         </div>

//         {/* Dynamic Subcategory Tabs */}
//         {subcategoriesInSelectedCategory.length > 0 && (
//           <div className="user-wardrobe scroll">
//             {subcategoriesInSelectedCategory.map((subcategory, index) => (
//               <button
//                 key={index}
//                 className={`button ${
//                   selectedSubcategory === subcategory ? "selected" : ""
//                 }`}
//                 onClick={() => handleSubcategorySelect(subcategory)}
//               >
//                 {subcategory}
//               </button>
//             ))}
//           </div>
//         )}

//         {/* Subcategory Images */}
//         <div className="subcategory-images">
//           {filteredData.map((item) => (
//             <div
//               key={item.ID}
//               className={`subcategory-item ${
//                 selectedItems[selectedOutfitCategory]?.ID === item.ID
//                   ? "selected"
//                   : ""
//               }`}
//               onClick={() => handleItemSelect(selectedOutfitCategory, item)}
//             >
//               <img
//                 src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
//                 alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
//               />
//               <p>{item.OUTFIT_TEXT}</p>
//             </div>
//           ))}
//         </div>

//         {/* Submit Button */}
//         {/* <div className="submit-container">
//         <button className="submit-btn" onClick={handleSubmit}>
//           Submit
//         </button>
//       </div> */}
//       </div>
//     </>
//   );
// };

// export default Accessories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import config from "../config";
// import "./css/Accessories.css";
// import { Spin, notification } from "antd";

// const Accessories = ({ SelectUserData }) => {
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
//   const fcmToken = sessionStorage.getItem("fcmToken");

//   // State to store accessories data, selected category, selected subcategory, selected items, and previously selected items
//   const [AccessoriesData, setAccessoriesData] = useState([]);
//   const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [selectedItems, setSelectedItems] = useState([]); // Now an array for multiple selections
//   const [previouslySelectedItems, setPreviouslySelectedItems] = useState([]);
//   const [file, setFile] = useState(null); // State for file upload

//   // Fetch user accessories data
//   const getUserAccessoriesData = async () => {
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getStylistCollages/${"Female"}`
//       );

//       if (response?.data?.recordset) {
//         const fetchedData = response.data.recordset;
//         setAccessoriesData(fetchedData);
//         console.log(response);

//         // Automatically select the first category and subcategory
//         const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
//         const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
//         setSelectedOutfitCategory(firstCategory);
//         setSelectedSubcategory(firstSubcategory);
//       }
//     } catch (error) {
//       console.error("Error fetching accessories data:", error);
//     }
//   };

//   // Fetch previously selected items
//   const getPreviouslySelectedItems = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(`${config.baseURL}getWebsiteSourceContent/${SelectUserData.user_id}`);

//       if (response?.data?.recordset && response.data.recordset.length > 0) {
//         const data = response.data.recordset[0];

//         // Parse the selected images and products from the response
//         const selectedImagesList = JSON.parse(JSON.parse(data.SELECTED_IMAGES_DATA));
//         const sampleImagesList = JSON.parse(JSON.parse(data.SOURCE_CONTENT));

//         setPreviouslySelectedItems(response.data.selectedImagesList);
//       }
//     } catch (error) {
//       console.error("Error fetching previously selected items:", error);
//     }
//   };

//   useEffect(() => {
//     getUserAccessoriesData();
//     getPreviouslySelectedItems();
//   }, []);

//   // Handle category selection
//   const handleCategorySelect = (category) => {
//     setSelectedOutfitCategory(category);

//     // Reset selected subcategory when category changes
//     const firstSubcategoryInCategory = AccessoriesData.find(
//       (item) => item.OUTFIT_CATEGORY === category
//     )?.OUTFIT_TEXT;
//     setSelectedSubcategory(firstSubcategoryInCategory);
//   };

//   // Handle subcategory (OUTFIT_TEXT) selection
//   const handleSubcategorySelect = (subcategory) => {
//     setSelectedSubcategory(subcategory);
//   };

//   // Handle item selection (multi-select)
//   const handleItemSelect = (category, item) => {
//     setSelectedItems((prevState) => {
//       const exists = prevState.some((selectedItem) => selectedItem.ID === item.ID);
//       if (exists) {
//         return prevState.filter((selectedItem) => selectedItem.ID !== item.ID); // Remove if already selected
//       }
//       return [...prevState, item]; // Add new selection
//     });
//   };

//   // Handle form submission
//   const handleSubmit = async () => {
//     console.log("Selected Items:", selectedItems);
//     const formData = new FormData();
//     formData.append("userId", LoginUser?.id);
//     formData.append("fcmToken", fcmToken);
//     formData.append("selectedItems", JSON.stringify(selectedItems));

//     if (file) {
//       formData.append("file", file);
//     }

//     try {
//       const response = await axios.post(`${config.baseURL}submitAccessories`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log("Submit response:", response);
//     } catch (error) {
//       console.error("Error submitting selected items:", error);
//     }

//     try {
//       if (selectedItems.length === 0) {
//         notification.error({
//           message: "No Images Selected",
//           description: "Please select at least one image to upload.",
//         });
//         return;
//       }

//       setLoading(true);
//       const formData = new FormData();
//       formData.append("CUSTOMER_ID", SelectUserData.user_id);
//       formData.append("STYLIST_ID", SelectUserData.stylist_id);
//       formData.append("SERVICE_ID", SelectUserData.SERVICE_ID);
//       formData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
//       formData.append("LINK_LIST", JSON.stringify([]));

//       selectedItems.forEach((image) => {
//         formData.append("IMAGE_LIST", image.OUTFIT_IMAGE);
//       });
//       formData.append("SOURCE_CONTENT", JSON.stringify(selectedItems));
//       formData.append("SELECTED_IMAGES_DATA", JSON.stringify(selectedItems));
//       formData.append("type", '3');
//       const uploadResponse = await axios.post(`${config.baseURL}websiteSourceContent`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       if (uploadResponse.status === 200) {
//         notification.success({
//           message: "Upload Success",
//           description: "Images have been uploaded successfully.",
//         });

//       } else {
//         notification.error({
//           message: "Upload Failed",
//           description: "Failed to upload images. Please try again.",
//         });
//       }
//     } catch (error) {
//       console.error("Error uploading images:", error);
//       notification.error({
//         message: "Upload Error",
//         description: "There was an error uploading images.",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Filter data based on the selected category and subcategory
//   const filteredData = AccessoriesData.filter(
//     (item) =>
//       item.OUTFIT_CATEGORY === selectedOutfitCategory &&
//       item.OUTFIT_TEXT === selectedSubcategory
//   );

//   // Extract unique categories and subcategories
//   const uniqueCategories = [
//     ...new Set(AccessoriesData.map((item) => item.OUTFIT_CATEGORY)),
//   ];

//   const subcategoriesInSelectedCategory = [
//     ...new Set(
//       AccessoriesData.filter(
//         (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
//       ).map((item) => item.OUTFIT_TEXT)
//     ),
//   ];

//   return (
//     <>
//       <div className="userProfileMain">
//         <h1 className="heading">Accessories</h1>
//       </div>
//       <div className="accessories-container">
//         {/* Dynamic Category Tabs */}
//         <div className="user-wardrobe scroll">
//           {uniqueCategories.map((category, index) => (
//             <button
//               key={index}
//               className={`button ${
//                 selectedOutfitCategory === category ? "selected" : ""
//               }`}
//               onClick={() => handleCategorySelect(category)}
//             >
//               {category}
//             </button>
//           ))}
//         </div>

//         {/* Dynamic Subcategory Tabs */}
//         {subcategoriesInSelectedCategory.length > 0 && (
//           <div className="user-wardrobe scroll">
//             {subcategoriesInSelectedCategory.map((subcategory, index) => (
//               <button
//                 key={index}
//                 className={`button ${
//                   selectedSubcategory === subcategory ? "selected" : ""
//                 }`}
//                 onClick={() => handleSubcategorySelect(subcategory)}
//               >
//                 {subcategory}
//               </button>
//             ))}
//           </div>
//         )}

//         {/* Subcategory Images (Multiple Selection) */}
//         <div className="subcategory-images">
//           {filteredData.map((item) => (
//             <div
//               key={item.ID}
//               className={`subcategory-item ${
//                 selectedItems.some((selectedItem) => selectedItem.ID === item.ID)
//                   ? "selected"
//                   : ""
//               }`}
//               onClick={() => handleItemSelect(selectedOutfitCategory, item)}
//               style={{
//                 border: selectedItems.some((selectedItem) => selectedItem.ID === item.ID)
//                   ? "2px solid blue"
//                   : "none",
//               }}
//             >
//               <img
//                 src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
//                 alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
//               />
//               <p>{item.OUTFIT_TEXT}</p>
//             </div>
//           ))}
//         </div>

//         {/* Display Previously Selected Items */}

//         {/* Submit Button */}
//         <div className="submit-container">
//           <button className="submit-btn" onClick={handleSubmit}>
//             Submit
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Accessories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Spin, notification } from "antd";
// import config from "../config";
// import "./css/Accessories.css";

// const Accessories = ({ SelectUserData }) => {
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
//   const fcmToken = sessionStorage.getItem("fcmToken");

//   // State to store accessories data, selected category, selected subcategory, selected items, previously selected items, and loading state
//   const [AccessoriesData, setAccessoriesData] = useState([]);
//   const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
//   const [selectedSubcategory, setSelectedSubcategory] = useState("");
//   const [selectedItems, setSelectedItems] = useState([]); // Now an array for multiple selections
//   const [previouslySelectedItems, setPreviouslySelectedItems] = useState([]);
//   const [file, setFile] = useState(null); // State for file upload
//   const [loading, setLoading] = useState(false); // Loading state

//   // Fetch user accessories data
//   const getUserAccessoriesData = async () => {
//     setLoading(true); // Start loading
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getStylistCollages/${SelectUserData.gender}`
//       );
//       if (response?.data?.recordset) {
//         const fetchedData = response.data.recordset;
//         setAccessoriesData(fetchedData);
//         console.log(response);

//         // Automatically select the first category and subcategory
//         const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
//         const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
//         setSelectedOutfitCategory(firstCategory);
//         setSelectedSubcategory(firstSubcategory);
//       }
//     } catch (error) {
//       console.error("Error fetching accessories data:", error);
//       notification.error({
//         message: "Error",
//         description: "Failed to load accessories data.",
//       });
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   // Fetch previously selected items
//   const getPreviouslySelectedItems = async () => {
//     setLoading(true); // Start loading
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getWebsiteSourceContent/${
//           SelectUserData.user_id
//         }/${"1"}`
//       );
//       if (response?.data?.recordset && response.data.recordset.length > 0) {
//         const data = response.data.recordset[0];

//         const selectedImagesList = JSON.parse(
//           JSON.parse(data.SELECTED_IMAGES_DATA)
//         );
//         console.log(selectedImagesList);
//         setSelectedItems(selectedImagesList);
//       }
//     } catch (error) {
//       console.error("Error fetching previously selected items:", error);
//       notification.error({
//         message: "Error",
//         description: "Failed to load previously selected items.",
//       });
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   useEffect(() => {
//     getUserAccessoriesData();
//     getPreviouslySelectedItems();
//   }, []);

//   // Handle category selection
//   const handleCategorySelect = (category) => {
//     setSelectedOutfitCategory(category);
//     const firstSubcategoryInCategory = AccessoriesData.find(
//       (item) => item.OUTFIT_CATEGORY === category
//     )?.OUTFIT_TEXT;
//     setSelectedSubcategory(firstSubcategoryInCategory);
//   };

//   // Handle subcategory selection
//   const handleSubcategorySelect = (subcategory) => {
//     setSelectedSubcategory(subcategory);
//   };

//   // Handle item selection (multi-select)
//   const handleItemSelect = (category, item) => {
//     setSelectedItems((prevState) => {
//       const exists = prevState.some(
//         (selectedItem) => selectedItem.ID === item.ID
//       );
//       if (exists) {
//         return prevState.filter((selectedItem) => selectedItem.ID !== item.ID); // Remove if already selected
//       }
//       return [...prevState, item]; // Add new selection
//     });
//   };

//   // Handle form submission
//   const handleSubmit = async () => {
//     if (selectedItems.length === 0) {
//       notification.error({
//         message: "No Images Selected",
//         description: "Please select at least one image to upload.",
//       });
//       return;
//     }
//     if (selectedItems.length <= 1) {
//       notification.error({
//         message: "Minimum",
//         description: "Please select at least Two image to upload.",
//       });
//       return;
//     }
//     setLoading(true); // Start loading

//     try {
//       //   const formData = new FormData();
//       //   formData.append("userId", LoginUser?.id);
//       //   formData.append("fcmToken", fcmToken);
//       //   formData.append("selectedItems", JSON.stringify(selectedItems));

//       //   if (file) {
//       //     formData.append("file", file);
//       //   }

//       //   const response = await axios.post(`${config.baseURL}submitAccessories`, formData, {
//       //     headers: { "Content-Type": "multipart/form-data" },
//       //   });

//       console.log("Submit selectedItems:", selectedItems);

//       const uploadData = new FormData();
//       uploadData.append("CUSTOMER_ID", SelectUserData.user_id);
//       uploadData.append("STYLIST_ID", SelectUserData.stylist_id);
//       uploadData.append("SERVICE_ID", SelectUserData.SERVICE_ID);
//       uploadData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
//       uploadData.append("LINK_LIST", JSON.stringify([]));

//       selectedItems.forEach((image) => {
//         uploadData.append("IMAGE_LIST", image.OUTFIT_IMAGE);
//       });
//       uploadData.append("SOURCE_CONTENT", JSON.stringify(selectedItems));
//       uploadData.append("SELECTED_IMAGES_DATA", JSON.stringify(selectedItems));
//       uploadData.append("type", "1");

//       const uploadResponse = await axios.post(
//         `${config.baseURL}websiteSourceContent`,
//         uploadData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );

//       if (uploadResponse.status === 200) {
//         notification.success({
//           message: "Upload Success",
//           description: "Images have been uploaded successfully.",
//         });
//         getPreviouslySelectedItems();
//       } else {
//         notification.error({
//           message: "Upload Failed",
//           description: "Failed to upload images. Please try again.",
//         });
//       }
//     } catch (error) {
//       console.error("Error uploading images:", error);
//       notification.error({
//         message: "Upload Error",
//         description: "There was an error uploading images.",
//       });
//     } finally {
//       setLoading(false); // Stop loading
//     }
//   };

//   // Filter data based on the selected category and subcategory
//   const filteredData = AccessoriesData.filter(
//     (item) =>
//       item.OUTFIT_CATEGORY === selectedOutfitCategory &&
//       item.OUTFIT_TEXT === selectedSubcategory
//   );

//   // Extract unique categories and subcategories
//   const uniqueCategories = [
//     ...new Set(AccessoriesData.map((item) => item.OUTFIT_CATEGORY)),
//   ];

//   const subcategoriesInSelectedCategory = [
//     ...new Set(
//       AccessoriesData.filter(
//         (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
//       ).map((item) => item.OUTFIT_TEXT)
//     ),
//   ];

//   return (
//     <>
//       <div className="userProfileMain">
//         <h1 className="heading">Accessories</h1>
//       </div>
//       <Spin spinning={loading}>
//         <p style={{ margin: "13px 19px 0" }}>
//           Note: Choose the images you would like to include in your collage.
//         </p>
//         <div className="accessories-container" style={{ width: "100%" }}>
//           {/* Dynamic Category Tabs */}
//           <div className="user-wardrobe scroll">
//             {uniqueCategories.map((category, index) => (
//               <button
//                 key={index}
//                 className={`button ${
//                   selectedOutfitCategory === category ? "selected" : ""
//                 }`}
//                 onClick={() => handleCategorySelect(category)}
//               >
//                 {category}
//               </button>
//             ))}
//           </div>

//           {/* Dynamic Subcategory Tabs */}
//           {subcategoriesInSelectedCategory.length > 0 && (
//             <div className="user-wardrobe scroll">
//               {subcategoriesInSelectedCategory.map((subcategory, index) => (
//                 <button
//                   key={index}
//                   className={`button ${
//                     selectedSubcategory === subcategory ? "selected" : ""
//                   }`}
//                   onClick={() => handleSubcategorySelect(subcategory)}
//                 >
//                   {subcategory}
//                 </button>
//               ))}
//             </div>
//           )}

//           {/* Subcategory Images (Multiple Selection) */}
//           <div>
//             {SelectUserData.status !== 4 && (
//               <div className="submit-container">
//                 <button
//                   className="button"
//                   onClick={handleSubmit}
//                   disabled={loading}
//                 >
//                   Submit
//                 </button>
//               </div>
//             )}
//             <div className="subcategory-images">
//               {filteredData.map((item) => (
//                 <div
//                   key={item.ID}
//                   className={`subcategory-item ${
//                     selectedItems.some(
//                       (selectedItem) => selectedItem.ID === item.ID
//                     )
//                       ? "selected"
//                       : ""
//                   }`}
//                   onClick={() => handleItemSelect(selectedOutfitCategory, item)}
//                   style={{
//                     border: selectedItems.some(
//                       (selectedItem) => selectedItem.ID === item.ID
//                     )
//                       ? "2px solid blue"
//                       : "1px solid #ccc",
//                   }}
//                 >
//                   <img
//                     src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
//                     alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
//                   />
//                   <p>{item.OUTFIT_TEXT}</p>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Submit Button */}
//         </div>
//       </Spin>
//     </>
//   );
// };

// export default Accessories;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, notification } from "antd";
import config from "../config";
import "./css/Accessories.css";

const Accessories = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");

  // State to store accessories data, selected category, subcategory, selected items, and loading state
  const [AccessoriesData, setAccessoriesData] = useState([]);
  const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch user accessories data
  const getUserAccessoriesData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${config.baseURL}getStylistCollages/${SelectUserData.gender}`
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        setAccessoriesData(fetchedData);

        // Automatically select the first category and subcategory
        const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
        const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
        setSelectedOutfitCategory(firstCategory);
        setSelectedSubcategory(firstSubcategory);

        // Filter data based on initial selection
        filterData(firstCategory, firstSubcategory, fetchedData);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load accessories data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch previously selected items
  const getPreviouslySelectedItems = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${SelectUserData.user_id}/1`
      );
      if (response?.data?.recordset && response.data.recordset.length > 0) {
        const data = response.data.recordset[0];
        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );
        setSelectedItems(selectedImagesList);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load previously selected items.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getUserAccessoriesData();
    getPreviouslySelectedItems();
  }, []);

  useEffect(() => {
    if (selectedOutfitCategory && selectedSubcategory) {
      filterData(selectedOutfitCategory, selectedSubcategory);
    }
  }, [selectedOutfitCategory, selectedSubcategory, AccessoriesData]);
  // Function to filter data based on category and subcategory
  const filterData = (category, subcategory, data = AccessoriesData) => {
    // console.log("Filtering data for:", category, subcategory);
    const filtered = data.filter(
      (item) =>
        item.OUTFIT_CATEGORY === category && item.OUTFIT_TEXT === subcategory
    );
    // console.log("Filtered data:", filtered); // Debugging statement
    setFilteredData(filtered);
  };

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedOutfitCategory(category);
    const firstSubcategoryInCategory = AccessoriesData.find(
      (item) => item.OUTFIT_CATEGORY === category
    )?.OUTFIT_TEXT;
    setSelectedSubcategory(firstSubcategoryInCategory);
  };

  // Handle subcategory selection
  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  // Handle item selection (multi-select)
  const handleItemSelect = (item) => {
    setSelectedItems((prevState) => {
      const exists = prevState.some(
        (selectedItem) => selectedItem.ID === item.ID
      );
      if (exists) {
        return prevState.filter((selectedItem) => selectedItem.ID !== item.ID);
      }
      return [...prevState, item];
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (selectedItems.length < 2) {
      notification.error({
        message: "Minimum",
        description: "Please select at least two images to upload.",
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      const uploadData = new FormData();
      uploadData.append("CUSTOMER_ID", SelectUserData.user_id);
      uploadData.append("STYLIST_ID", SelectUserData.stylist_id);
      uploadData.append("SERVICE_ID", SelectUserData.SERVICE_ID);
      uploadData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
      uploadData.append("LINK_LIST", JSON.stringify([]));

      selectedItems.forEach((image) => {
        uploadData.append("IMAGE_LIST", image.OUTFIT_IMAGE);
      });

      uploadData.append("SOURCE_CONTENT", JSON.stringify(selectedItems));
      uploadData.append("SELECTED_IMAGES_DATA", JSON.stringify(selectedItems));
      uploadData.append("type", "1");

      const uploadResponse = await axios.post(
        `${config.baseURL}websiteSourceContent`,
        uploadData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (uploadResponse.status === 200) {
        notification.success({
          message: "Upload Success",
          description: `${selectedItems.length} Images have been uploaded successfully.`,
        });
        getPreviouslySelectedItems(); // Refresh previously selected items
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Failed to upload images. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Upload Error",
        description: "There was an error uploading images.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    console.log("Filtered Data:", filteredData);
  }, [filteredData]);

  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  // Extract unique categories and subcategories
  const uniqueCategories = [
    ...new Set(AccessoriesData.map((item) => item.OUTFIT_CATEGORY)),
  ];

  const subcategoriesInSelectedCategory = [
    ...new Set(
      AccessoriesData.filter(
        (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
      ).map((item) => item.OUTFIT_TEXT)
    ),
  ];

  return (
    <>
      <div className="userProfileMain">
        <h1 className="heading">Accessories</h1>
      </div>
      <Spin spinning={loading}>
        <p style={{ margin: "13px 19px 0" }}>
          Note: Choose the images you would like to include in your collage.
        </p>
        <div className="accessories-container" style={{ width: "100%" }}>
          {/* Dynamic Category Tabs */}
          <div className="user-wardrobe scroll">
            {uniqueCategories.map((category, index) => (
              <button
                key={index}
                className={`button ${
                  selectedOutfitCategory === category ? "selected" : ""
                }`}
                onClick={() => handleCategorySelect(category)}
              >
                {capitalizeFirstLetter(category)}
              </button>
            ))}
          </div>

          {/* Dynamic Subcategory Tabs */}
          {subcategoriesInSelectedCategory.length > 0 && (
            <div className="user-wardrobe scroll">
              {subcategoriesInSelectedCategory.map((subcategory, index) => (
                <button
                  key={index}
                  className={`button ${
                    selectedSubcategory === subcategory ? "selected" : ""
                  }`}
                  onClick={() => handleSubcategorySelect(subcategory)}
                >
                  {capitalizeFirstLetter(subcategory)}
                </button>
              ))}
            </div>
          )}

          {/* Subcategory Images (Multiple Selection) */}
          {SelectUserData.status !== 4 && (
              <div className="submit-container" style={{width:'100%'}}>
                <button
                  className="button"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {/* Submit */}
                  Add
                </button>
              </div>
            )}
          <div>
           
            <div className="subcategory-images">
              {/* {filteredData.length > 0 ? ( */}
              {filteredData.map((item,index) => (
                <div
                  key={index}
                  className={`subcategory-item ${
                    selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleItemSelect(item)}
                  style={{
                    border: selectedItems.some(
                      (selectedItem) => selectedItem.ID === item.ID
                    )
                      ? "2px solid blue"
                      : "1px solid #ccc",
                  }}
                  onDragStart={(e) => {

                    e.dataTransfer.setData("application/json", item.OUTFIT_IMAGE);
                  }}
                >
                  <img
                    src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
                    alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
                  />
                  <p>{item.OUTFIT_TEXT}</p>
                </div>
              ))}
               {/* ) : (  <p>No items available for this selection.</p>
              )} */}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Accessories;
