

import React, { useState } from "react";
import { Carousel } from "antd";
import "./Carousel.css";
import loginimage1 from "../../images/Carousel/loginimage1.png";
import loginimage2 from "../../images/Carousel/loginimage2.png";
import loginimage3 from "../../images/Carousel/loginimage3.png";

const CarouselComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    { id: 1, src: loginimage1, alt: "Login Image 1" },
    { id: 2, src: loginimage2, alt: "Login Image 2" },
    { id: 3, src: loginimage3, alt: "Login Image 3" },
    { id: 1, src: loginimage1, alt: "Login Image 1" },
    { id: 2, src: loginimage2, alt: "Login Image 2" },
    { id: 3, src: loginimage3, alt: "Login Image 3" },
  ];

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  return (
    <div className="Carousel-Main-Div">
      <div className="carousel-container">
        <Carousel
          autoplay
          beforeChange={handleBeforeChange}
          className="custom-carousel"
          dots={false}
          infinite
          stopOnHover={false}
          slidesToShow={3}
          centerMode
          // style={{ width: '55rem', margin: '0 auto' }}
        >
          {images.map((image, index) => {
            // Calculate the position relative to the current slide
            const previousSlide =
              (currentSlide - 1 + images.length) % images.length;
            const nextSlide = (currentSlide + 1) % images.length;

            let className = "";
            if (index === currentSlide) {
              className = "center-slide"; // Center image
            } else if (index === previousSlide || index === nextSlide) {
              className = "side-slide"; // Adjacent images
            }

            return (
              <div key={image.id} className={`carousel-item ${className}`}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className="carousel-content"
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselComponent;
