import React from "react";
import config from "../../config";
import "./css/CollageShoppingComponent.css";
const CollageShoppingComponent = ({ brand, imageURL, name, price, item }) => {
  return (
    <div
      className="CollageShoppingComponent"
      onDragStart={(e) => {
        
        
        const imageData = JSON.stringify(item);
        e.dataTransfer.setData("application/json", imageData);
      }}
    >
      <img className="img" src={`${config.imageUrl}/${imageURL}`} alt={name} />
      

      <div className="px-6 py-4">
        <p className="name">{name}</p>
        <p className="price">
          {brand ? "₹" : ""}
          {price !== "N/A" ? price : "Price not available"} {brand}
        </p>
       
      </div>
    </div>
  );
};

export default CollageShoppingComponent;
