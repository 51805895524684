// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import config from "../../config";
// import { Spin } from "antd";
// import QuestionAnswerComponent from "../cards/QuestionAnswerComponent";
// import { notification, Space } from "antd";

// const Guidelines = ({ SelectUserData }) => {
//   const [UserReportDetails, setUserReportDetails] = useState([]);
//   const [UserAvoidColors, setUserAvoidColors] = useState([]);
//   const [UserColorAnalysis, setUserColorAnalysis] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const [api, contextHolder] = notification.useNotification();
//   const openNotificationWithIcon = (type, msg) => {
//     api[type]({
//       message: "Notification Title",
//       description: msg,
//       duration: 10,
//     });
//   };
//   const getUserGuidelinesDataReportDetails = async (userId) => {
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getStyleReportDetails/${userId}`
//       );
//       if (response?.data?.recordset) {
//         console.log("getStyleReportDetails", response.data.recordset);
//         setUserReportDetails(response.data.recordset?.[0]);
//       }
//     } catch (error) {
//       console.error("Error fetching report details:", error);
//     }
//   };

//   const getUserGuidelinesDataColorAnalysis = async (userId) => {
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getColorAnalysis/${userId}`
//       );
//       if (response?.data?.recordset) {
//         setUserColorAnalysis(response.data.recordset);
//       }
//     } catch (error) {
//       console.error("Error fetching color analysis:", error);
//     }
//   };

//   const getUserGuidelinesDataAvoidColors = async (userId) => {
//     try {
//       const response = await axios.get(
//         `${config.baseURL}getAvoidColors/${userId}`
//       );
//       // console.log("getColorAnalysis",response,`${config.baseURL}getAvoidColors/${userId}`)
//       // console.log(response.data.recordset,".............")
//       if (response?.data?.recordset) {
//         setUserAvoidColors(response.data.recordset);
//       }
//     } catch (error) {
//       console.error("Error fetching avoid colors:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       if (SelectUserData?.user_id) {
//         await Promise.all([
//           getUserGuidelinesDataReportDetails(SelectUserData.user_id),
//           getUserGuidelinesDataColorAnalysis(SelectUserData.user_id),
//           getUserGuidelinesDataAvoidColors(SelectUserData.user_id),
//         ]);
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [SelectUserData]);

//   // if (loading) {
//   //   return <Spin tip="Loading..." style={{ marginTop: "5%" }} />;
//   // }
//   function getContrastYIQ(hexcolor) {
//     hexcolor = hexcolor.replace("#", "");
//     const r = parseInt(hexcolor.substr(0, 2), 16);
//     const g = parseInt(hexcolor.substr(2, 2), 16);
//     const b = parseInt(hexcolor.substr(4, 2), 16);
//     const yiq = (r * 299 + g * 587 + b * 114) / 1000;
//     return yiq >= 128 ? "black" : "white";
//   }

//   const copyToClipboard = (text) => {
//     navigator.clipboard
//       .writeText(text)
//       .then(() => {
//         // alert(`Copied to clipboard: ${text}`);

//         openNotificationWithIcon("success", `Copied to clipboard: ${text}`);
//       })
//       .catch((err) => {
//         console.error("Failed to copy: ", err);
//       });
//   };
//   return (
//     <div>
//       {contextHolder}{" "}
//       {loading ? (
//         <div className="loading">
//           <Spin tip="Loading..." style={{ marginTop: "5%" }} />
//         </div>
//       ) : (
//         <div>
//           <div className="userProfileMain">
//             <h1 className="heading"> Color Palette</h1>
//           </div>
//           {/* <QuestionAnswerComponent
//             question="Personal Assessment"
//             answer={UserReportDetails?.PERSONAL_ASSESSMENT_VALUE}
//           />
//           <QuestionAnswerComponent
//             question="Body Shape"
//             answer={UserReportDetails?.BODY_SHAPE_VALUE}
//           />
//           <QuestionAnswerComponent
//             question="Face Shape"
//             answer={UserReportDetails?.FACE_SHAPE_VALUE}
//           /> */}
//           <div className="question-answer-container">
//             <div className="question">Season - <span className="question-answer-container-span-uestion" style={{fontSize:'15px', color:'#949292'}}>True Winter</span></div>
//             <div className="answer">
//             </div>
//             <div style={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
//               {UserColorAnalysis.map((item, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     background: item.COLOR_CODE,
//                     width: "10%",
//                     padding: "19px 10px",
//                     color: getContrastYIQ(item.COLOR_CODE),
//                     textAlign: "center",
//                     marginBottom: "15px",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => copyToClipboard(item.COLOR_CODE)} // Handle click event
//                 >
//                   {/* {item.COLOR_CODE} */}
//                 </div>
//               ))}
//             </div>
//           </div>

//           <div className="question-answer-container">
//             <div className="question">Colors to Av🚫id</div>

//             <div style={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
//               {UserAvoidColors.map((item, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     background: item.COLOR_CODE,
//                     width: "10%",
//                     padding: "19px 10px",
//                     color: getContrastYIQ(item.COLOR_CODE),
//                     textAlign: "center",
//                     marginBottom: "15px",
//                     boxSizing: "border-box",
//                     borderRadius: "8px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => copyToClipboard(item.COLOR_CODE)} // Handle click event
//                 >
//                   {/* {item.COLOR_CODE} */}
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Guidelines;



import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { Spin } from "antd";
import QuestionAnswerComponent from "../cards/QuestionAnswerComponent";
import { notification, Space } from "antd";

const Guidelines = ({ SelectUserData }) => {
  const [UserReportDetails, setUserReportDetails] = useState([]);
  const [UserAvoidColors, setUserAvoidColors] = useState([]);
  const [UserColorAnalysis, setUserColorAnalysis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredColor, setHoveredColor] = useState(null); // State to track hovered color

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: "Notification Title",
      description: msg,
      duration: 10,
    });
  };

  const getUserGuidelinesDataReportDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}getStyleReportDetails/${userId}`
      );
      if (response?.data?.recordset) {
        setUserReportDetails(response.data.recordset?.[0]);
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };

  const getUserGuidelinesDataColorAnalysis = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}getColorAnalysis/${userId}`
      );
      if (response?.data?.recordset) {
        setUserColorAnalysis(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching color analysis:", error);
    }
  };

  const getUserGuidelinesDataAvoidColors = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}getAvoidColors/${userId}`
      );
      if (response?.data?.recordset) {
        setUserAvoidColors(response.data.recordset);
      }
    } catch (error) {
      console.error("Error fetching avoid colors:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (SelectUserData?.user_id) {
        await Promise.all([
          getUserGuidelinesDataReportDetails(SelectUserData.user_id),
          getUserGuidelinesDataColorAnalysis(SelectUserData.user_id),
          getUserGuidelinesDataAvoidColors(SelectUserData.user_id),
        ]);
        setLoading(false);
      }
    };
    fetchData();
  }, [SelectUserData]);

  function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        openNotificationWithIcon("success", `Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div>
      {contextHolder}{" "}
      {loading ? (
        <div className="loading">
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        </div>
      ) : (
        <div>
          <div className="userProfileMain">
            <h1 className="heading"> Color Palette</h1>
          </div>
          <div className="question-answer-container">
            <div className="question">
              Season - <span className="question-answer-container-span-uestion" style={{ fontSize: '15px', color: '#949292' }}>True Winter</span>
            </div>
            <div className="answer"></div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
              {UserColorAnalysis.map((item, index) => (
                <div
                  key={index}
                  style={{
                    background: item.COLOR_CODE,
                    width: "10%",
                    padding: "19px 10px",
                    color: getContrastYIQ(item.COLOR_CODE),
                    textAlign: "center",
                    marginBottom: "15px",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    cursor: "pointer",
                    position: "relative", // Set position relative for hover effect
                  }}
                  onClick={() => copyToClipboard(item.COLOR_CODE)} // Handle click event
                  onMouseEnter={() => setHoveredColor(item.COLOR_CODE)} // Set hovered color on mouse enter
                  onMouseLeave={() => setHoveredColor(null)} // Reset hovered color on mouse leave
                >
                  {/* Conditionally show color code on hover */}
                  {hoveredColor === item.COLOR_CODE && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "100%",
                        backgroundColor: "#000",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.COLOR_CODE}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="question-answer-container">
            <div className="question">Colors to Av🚫id</div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "2%" }}>
              {UserAvoidColors.map((item, index) => (
                <div
                  key={index}
                  style={{
                    background: item.COLOR_CODE,
                    width: "10%",
                    padding: "19px 10px",
                    color: getContrastYIQ(item.COLOR_CODE),
                    textAlign: "center",
                    marginBottom: "15px",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    cursor: "pointer",
                    position: "relative", // Set position relative for hover effect
                  }}
                  onClick={() => copyToClipboard(item.COLOR_CODE)} // Handle click event
                  onMouseEnter={() => setHoveredColor(item.COLOR_CODE)} // Set hovered color on mouse enter
                  onMouseLeave={() => setHoveredColor(null)} // Reset hovered color on mouse leave
                >
                  {/* Conditionally show color code on hover */}
                  {hoveredColor === item.COLOR_CODE && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "100%",
                        backgroundColor: "#000",
                        color: "#fff",
                        padding: "5px",
                        borderRadius: "5px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.COLOR_CODE}
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Guidelines;
