import React, { useState, useEffect } from "react";
import { Spin, Flex, message, Button, notification } from "antd";
import axios from "axios";
import config from "../../config";
import {} from "@ant-design/icons";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
import Collage from "../Collage/Collage";
const WardrobeCollage = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(true);
  const [collageList, setcollageList] = useState([]);
  const [UserImagesData, setUserImagesData] = useState([]);
  const [uploadDragImageList, setuploadDragImageList] = useState([]);
  const [axis, setaxis] = useState(0);
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const fcmToken = sessionStorage.getItem("fcmToken");

  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");
  const getUserImagesData = async (userId) => {
    console.log(SelectUserData, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}getImages/${SelectUserData?.user_id}/${SelectUserData?.gender}`
      );
      // console.log(response, "................");
      if (response?.data?.recordset) {
        // console.log("....");
        const data = response?.data?.recordset;
        console.log(data, ".data...");
        setUserImagesData(data);
        // setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${SelectUserData.stylist_id}/${1}`
      );
      console.log(response, "get collage data");
      if (response?.data?.recordset) {
        console.log(
          response.data.recordset,
          "get collage dataonli",
          response.data.recordset.length
        );
        setcollageList(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };
  const getUserCollageData = async (userId) => {
    console.log(SelectUserData, ".....");
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${SelectUserData.stylist_id}/${0}`
      );
      // console.log(response)
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");

        setcollageList(response.data.recordset);
        setloading(false);
        if (response.data.recordset.length === 0) {
          console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };
  useEffect(() => {
    getUserCollageData();
    getUserImagesData();
    // handleTemplateSelection(5)
  }, [SelectUserData]);
  useEffect(() => {
    // This useEffect will run every time COLLAGE_DESCRIPTION or fileToUpload changes
    if (COLLAGE_DESCRIPTION ) {
       // Call the upload function after state has been updated
    }
  }, [COLLAGE_DESCRIPTION]);
  const CollageNotify = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const handleDelete = async (record) => {
    console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        await getUserCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const updateCollage = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),

        COLLAGE: "Stylist completed your outfit",
        SERVICE_ID: SelectUserData.SERVICE_ID.toString(),
        CUSTOMER_ID: SelectUserData.user_id.toString(),
        STYLIST_TOKEN: fcmToken,
        CUSTOMER_TOKEN: SelectUserData.fcm_token,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        notification.info({
          message: "Success",
          description: "I have sent the collage to the user.",
        });
        await CollageNotify();
        await getUserCollageData();
        await getUserImagesData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  function modifyUrls(urls) {
    const baseUrl = "https://skandaqa.stylz.in";

    return urls.map((url) => {
      if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, ""); // Remove the base URL
      }
      return url; // If it doesn't match, return the original URL
    });
  }
  const UploadSingleColllage = async (path, DESCRIPTION) => {
    console.log(uploadDragImageList, "uploadDragImageList.............");
    try {
      const formData = new FormData();
      if (path) {
        formData.append("picture", path);
      }

      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),

          STYLIST_ID: SelectUserData.stylist_id,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: modifyUrls(uploadDragImageList),
          LINK_LIST: modifyUrls(uploadDragImageList).toString(),
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_ID,
          AXIS: 0,
          SCRAPE_DATA: "",
          COLLAGE_DESCRIPTION: COLLAGE_DESCRIPTION,
        })
      );

      const response = await axios.post(
        `${config.baseURL}createdCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        console.log(response.data);
        message.success(" Collage Created successfully");
        // await collageImagesList();

        await getUserCollageData();
        await getUserImagesData();
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      // throw error;
    }
  };

  const uploadCreatedCollage = async (file, DESCRIPTION) => {
    console.log(file, ";;;;;.......................");
    await setCOLLAGE_DESCRIPTION(DESCRIPTION);
    await UploadSingleColllage(file, DESCRIPTION);
  };
  const handleTemplateSelection = (template) => {
    setTemplateSelection(template);
  };
  return (
    <div>
      {loading ? (
        <div className="loading">
          <Spin tip="Loading..." style={{ marginTop: "5%" }} />
        </div>
      ) : (
        <div>
          {axis === 1 && (
            <div>
              <div>
                <h1 className="heading"> View Collage</h1>
              </div>
              <div className="onlineProduct onlineProductWardrobe">
                {collageList.map((item, index) => (
                  <CollageComponent
                    key={index}
                    count={index + 1}
                    imageUrl={`${item?.IMAGE_NAME}`}
                    Delete={() => {
                      setloading(true);
                      setaxis(0);
                      handleDelete(item);
                    }}
                    item={item}
                  />
                ))}
              </div>
            </div>
          )}
          {axis === 0 && (
            <>
              <div style={{ margin: "2% 0" }}>
                {UserImagesData && (
                  <>
                    {collageList.length < 1 && (
                      <Collage
                        key={`template-${TemplateSelection}`}
                        UserWardrobeImagesData={UserImagesData}
                        defaultFrameSize={
                          TemplateSelection ? TemplateSelection : 5
                        }
                        SelectUserData={SelectUserData}
                        uploadCreatedCollage={uploadCreatedCollage}
                        uploadDragImageList={(data) =>
                          setuploadDragImageList(data)
                        }
                        SendinputText={(data) => setCOLLAGE_DESCRIPTION(data)}
                        // SendImageLinks={(data)=>}
                      />
                    )}

                    {collageList.map((item, index) => (
                      <CollageComponent
                        key={index}
                        count={index + 1}
                        imageUrl={`${item?.IMAGE_NAME}`}
                        Delete={() => {
                          setloading(true);
                          setaxis(0);
                          handleDelete(item);
                        }}
                        item={item}
                      />
                    ))}
                    {axis === 0 && (
                      <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
                        <Button
                          className="button"
                          disabled={collageList.length !== 1}
                          onClick={
                            collageList.length === 1 ? collageImagesList : null
                          }
                        >
                          Upload Collage
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
              </div>
              {/* )} */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WardrobeCollage;
