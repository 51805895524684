import React from "react";
import "./css/UserDisplayCard.css";
import config from "../../config";
import Profile from "../../images/profile.jpg";
import { Flex } from "antd";
const UserDisplayCard = ({
  image,
  name,
  dob,
  purchasedate,
  enddate,
  category,
  buttonText,
  background,
  onClick,
  user,
  stylistName,
}) => {
  function extractBaseUrl(url) {
    // Split the URL by "https://" and filter out any empty parts
    const parts = url.split("https://").filter(Boolean);

    // Construct the base URL by joining the last part with "https://"
    return `https://${parts[parts.length - 1]}`;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle 00 hour as 12 AM

    return `${day} ${month}, ${year} ${hours}:${minutes}${ampm}`;
  }
  return (
    <div
      className="user-display-card"
      style={{
        background: `${background}`,
       // borderTopRightRadius: `${stylistName ? "0px" : "8px"}`,
      }}
    >
      <div className="first-div">
        <div className="image-container">
          <img
            src={`${
              image ? extractBaseUrl(`${config.imageUrl}${image}`) : Profile
            }`}
            alt="User"
          />
        </div>
        <div className="info-container">
          <div className="info-item-container">
            <div className="info-item">
              <Flex gap={20}>
                <p style={{ margin: "0" }}> {name ? name : "No Name"}</p>
                {user?.status === 4 && (
                  <p style={{ margin: "0" }}>
                    {" "}
                    {user?.gender ? user.gender : ""}
                  </p>
                )}
              </Flex>
              <Flex gap={20}>
                <div className="info-itemdob">
                  <div>Purchased Date:</div>
                  <div className="User-Purc-Date-Div">
                    {purchasedate
                      ? formatDate(purchasedate)
                      : user?.enrollment_date
                      ? formatDate(user?.enrollment_date)
                      : ""}
                  </div>
                </div>

                {user?.status === 4 && (
                  <div className="info-itemdob">
                    <div>Close Service :</div>
                    <div className="User-Purc-Date-Div">
                      {enddate
                        ? formatDate(enddate)
                        : user?.end_date
                        ? formatDate(user?.end_date)
                        : ""}
                    </div>
                  </div>
                )}
              </Flex>
            </div>
            <div>
              <p>{category ? category : ""}</p>
            </div>
          </div>
          <div className="button-container">
          {stylistName && (
              <div className="hovertodisplaydivm">
                <p
                  className="text"
                  style={{
                    background: `${background}`,
                  }}
                >
                Stylist : {stylistName ? stylistName : ""}
                </p>
              </div>
            )}
            <button onClick={onClick}>{buttonText}</button>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDisplayCard;
