import React, { useState, useEffect } from "react";
import { DatePicker, Button, Typography, Row, Col, Spin, message } from "antd";
import axios from "axios";
import config from "../../config";
import CurrentServicesCard from "../cards/CurrentServicesCard";
import UserDisplayCard from "../cards/UserDisplayCard";
function Assigned({ SelectUserData, AssignedStyle }) {
  const [shoptype, setShopType] = useState("Shopping");

  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);

  const [loading, setloading] = useState(true);
  const getCustomerDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}${
          LoginUser.ROLE === "0" ? "getCustomerDetails" : "getCustomerDetails1"
        }/${userId}`
      );

      // Handle the response as needed
      console.log(response.data);
      if (response?.data?.recordset) {
        console.log(
          " response?.data?.recordset....",
          response?.data?.recordset
        );
        // const data = response?.data?.recordset;
        const data = response?.data?.recordset?.filter(
          (record) => record.status !== 0
        );
        setUserData(data);
        setloading(false);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };

  useEffect(() => {
    // console.log(LoginUser, "LoginUser");
    getCustomerDetails(LoginUser?.USER_ID);
  }, [shoptype, AssignedStyle]);

  const onClick = (data) => {
    console.log(data, "Onclick data ....");
    SelectUserData(data);
  };
  return (
    <div className="heading-container">
      {LoginUser.ROLE === "0" ? (
        <h1 className="heading">Stylist Assigned</h1>
      ) : (
        <h1
          className="heading"
          style={{ textAlign: "left", paddingLeft: "2%" }}
        >
          Current Services
        </h1>
      )}
      <div className="body-section">
        {/* <div className="selectedShoptypes">
          <button
            className={shoptype === "Shopping" ? "Shopping" : "unShopping"}
            onClick={() => setShopType("Shopping")}
          >
            Shopping
          </button>
          <button
            className={shoptype === "Wardrobe" ? "Wardrobe" : "unWardrobe"}
            onClick={() => setShopType("Wardrobe")}
          >
            Wardrobe
          </button>
        </div> */}
        {!loading ? (
          <>
            {UseData.length > 0 ? (
              <>
                {UseData.map((user) => (
                  <>
                    {" "}
                    {LoginUser.ROLE === "0" ? (
                      // <UserDisplayCard
                      //   key={user.user_id}
                      //   image={user.user_photo}
                      //   name={user.first_name}
                      //   dob={user.age}
                      //   buttonText={"Continue"}
                      //   background={`${user?.service_category === "Digital Wardrobe" ? "#FBF1E9" : "#ffffbc"}`}

                      //   onClick={() => onClick(user)}
                      // />
                      <UserDisplayCard
                        key={user.user_id}
                        image={user.user_photo}
                        name={user.first_name}
                        dob={user.age}
                        category={user.service_category}
                        user={user}
                        stylistName={user.stylist_name}
                        buttonText={"Continue"}
                        background={`${
                          user?.service_category === "Digital Wardrobe"
                            ? "#FBF1E9"
                            : "#ffffbc"
                        }`}
                        onClick={() => onClick(user)}
                      />
                    ) : (
                      <CurrentServicesCard
                        key={user.user_id}
                        image={user.user_photo}
                        name={user.first_name}
                        dob={user.age}
                        gender={user.gender}
                        category={user.service_category}
                        user={user}
                        buttonText={"Continue"}
                        background={`${
                          user?.service_category === "Digital Wardrobe"
                            ? "#FBF1E9"
                            : "#ffffbc"
                        }`}
                        onClick={() => onClick(user)}
                      />
                    )}
                  </>
                ))}
              </>
            ) : (
              <>
                {LoginUser.ROLE === "0" ? (
                  <div className="nouserassigned">
                    <h3>No user assigned.</h3>
                  </div>
                ) : (
                  <div className="nouserassigned">
                    <h3>No user assigned to you.</h3>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Assigned;
