import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import {
  LinkOutlined,
  AppstoreOutlined,
  AppstoreFilled,
} from "@ant-design/icons";
import { Input, Flex, Button, message ,notification} from "antd";
import { Spin } from "antd";
import "./ShoppingCollage.css";
import CollageComponent from "../cards/CollageComponent";
import ImageUploadAndPreview from "./ImageUploadAndPreview";
import Collage from "../Collage/Collage";
import CollageShoppingComponent from "../cards/CollageShoppingComponent";
const ShoppingCollage = ({ SelectUserData }) => {
  const [UserShoppingCollageData, setUserShoppingCollageData] = useState([]);
  const [linkInputs, setLinkInputs] = useState(Array(8).fill(""));
  const [validationError, setValidationError] = useState("");
  const [requiredLinks, setRequiredLinks] = useState(0);
  const [dispalycollagecretaion, setdispalycollagecretaion] = useState(false);
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [product, setproduct] = useState([]);
  const [productimagelink, setproductimagelink] = useState([]);
  const [dispalProduct, setdispalProduct] = useState(false);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(false);
  const [axis, setaxis] = useState(0);
  const [sendfileDataList, setsendfileDataList] = useState([]);
  const [displaLinkData, setdisplaLinkData] = useState(true);
  const [COLLAGE_DESCRIPTION, setCOLLAGE_DESCRIPTION] = useState("");
  const [TemplateSelection, setTemplateSelection] = useState(0);
  const fcmToken = sessionStorage.getItem("fcmToken");
  // const getUserShoppingCollageData = async (userId) => {
  //   try {
  //     const response = await axios.get(
  //       `${config.baseURL}userSelectedAnswers/${userId}/SHOPPING_ASSISTANT`
  //     );

  //     if (response?.data?.recordset) {
  //       const data = response?.data?.recordset;
  //       setUserShoppingCollageData(data);
  //     }
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching unassigned details:", error);
  //     throw error;
  //   }
  // };
  function modifyUrls(urls) {
    const baseUrl = "https://skandaqa.stylz.in";

    return urls.map((url) => {
      if (url.startsWith(baseUrl)) {
        return url.replace(baseUrl, ""); // Remove the base URL
      }
      return url; // If it doesn't match, return the original URL
    });
  }

  function matchImagesToProducts(selectedImagesList, productsJson) {
    // Parse the JSON string into a JavaScript object
    const products = JSON.parse(productsJson);

    // Initialize an array to store matching products
    const matchedProducts = [];

    // Loop through each image in the selectedImagesList
    selectedImagesList.forEach((imageUrl) => {
      // Loop through the products
      products.forEach((product) => {
        // Check if the imageURL[0] of the product matches the current imageUrl
        if (product.imageURL[0] === imageUrl) {
          // If it matches, push the product to the matchedProducts array
          matchedProducts.push(product);
        }
      });
    });

    // Return the matched products array
    return matchedProducts;
  }

  function extractUrls(data) {
    try {
      // Check if data is a string; if so, parse it into an object
      const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
      
      // Extract the 'url' field from each object in the array
      const urls = parsedData.map(item => {
        if (item.url) {
          return item.url; // Return the URL if it exists
        } else {
          return item; // Return the entire item if URL does not exist
        }
      });
      
      
      return urls;
    } catch (error) {
      console.error("Invalid data provided", error);
      return [];
    }
  }
  const UploadSingleColllage = async (path,DESCRIPTION,fileDataList,fileList) => {
    console.log(product, "Product........");
    // const selectedImagesList = JSON.parse(
    //   sessionStorage.getItem("linkSelectedData")
    // );
    // const filteredLinks = linkInputs.filter((link) => link.trim() !== "");
    // const matchedProducts = matchImagesToProducts(
    //   modifyUrls(productimagelink),
    //   selectedImagesList
    // );

    console.log(path,DESCRIPTION,fileDataList,fileList,'fileDataList.....');
    try {
      const formData = new FormData();
      console.log(product, "product........");
      if (path) {
        formData.append("picture", path);
      }
      console.log(
        fileDataList,
        "sendfileDataList.........sendfileDataList"
      );

      // const linkSelectedData=sessionStorage.getItem('linkSelectedData');
      console.log(
        path,
        "image paths",
        productimagelink,
        modifyUrls(productimagelink),
        fileDataList,extractUrls(fileDataList)
      );
      formData.append(
        "data",
        JSON.stringify({
          USER_ID: SelectUserData.user_id.toString(),
          STYLIST_ID: SelectUserData.stylist_id,
          USER_SERVICE_ID: SelectUserData.user_service_id,
          IMAGE_NAME: path.name,
          IMAGE_LIST: modifyUrls(fileList),
          LINK_LIST: `[${extractUrls(fileDataList).join(", ")}]`,
          UPLOADED_BY: LoginUser.USER_ID,
          SERVICE_ID: SelectUserData.SERVICE_ID,
          AXIS: 0,
          SCRAPE_DATA: "",
          JsonResponse: fileDataList,
          COLLAGE_DESCRIPTION: COLLAGE_DESCRIPTION?COLLAGE_DESCRIPTION:DESCRIPTION,
        })
      );

      const response = await axios.post(
        `${config.baseURL}shoppingCollageList`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        console.log(response, "lsmdlnlsnlnl....colaage.......");
        getUserShoppingCollageData();
        setloading(false);
        setdispalycollagecretaion(false);
        setLinkInputs(Array(8).fill(""));
        setproductimagelink([]);
        setproduct([]);

        message.success(" Collage Created successfully");
      }
      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };
  const getUseronlineCollageData = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${SelectUserData.stylist_id}/${1}`
      );
      console.log(response, "get collage data");
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");
        setUserShoppingCollageData(response.data.recordset);

        if (response.data.recordset.length !== 0) {
          console.log("axis....", response.data.recordset.length);
          setaxis(1);
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };
  const getUserShoppingCollageData = async () => {
    try {
      const response = await axios.get(
        `${config.baseURL}getCreatedCollages/${SelectUserData.user_id}/${
          SelectUserData.SERVICE_ID
        }/${SelectUserData.user_service_id}/${SelectUserData.stylist_id}/${0}`
      );
      // console.log(response)
      if (response?.data?.recordset) {
        console.log(response.data.recordset, "get collage data");

        setUserShoppingCollageData(response.data.recordset);
        if (response.data.recordset.length === 0) {
          console.log("0....");

          await getUseronlineCollageData();
        }
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
  };

  const CreateCollageUseLink = async (urls) => {
    console.log([urls], "isdjifljsdiopmp");
    try {
      const params = new URLSearchParams();

      // Add each URL to the 'urls' key in the params
      urls.filter(Boolean).forEach((url) => {
        params.append("urls", url);
      });
      const response = await axios.post(`${config.baseURL}scrape`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      console.log(response, "kdsnknskdnk....out");

      // Handle the response as needed
      if (response?.data) {
        const links = response.data.map((data) => data.imageURL);
        console.log(response.data, "kdsnknskdnk....out", links);
        setproduct(response.data);
        setproductimagelink(links);
        setloading(false);
      }

      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const handleLinkChange = (index, e) => {
    const newLinkInputs = [...linkInputs];
    newLinkInputs[index] = e.target.value;
    setLinkInputs(newLinkInputs);
    // console.log("Updated linkInputs:", newLinkInputs);
  };
  const handleTemplateSelection = (template) => {
    setRequiredLinks(template);
  };
  const handleTemplateSelectionsubmmit = (template) => {
    setTemplateSelection(template);
    if (UserShoppingCollageData.length < 3) {
      setRequiredLinks(template);

      // Determine which indexes should be highlighted
      const requiredIndexes = Array.from({ length: template }, (_, i) => i);
      const providedLinksCount = linkInputs
        .slice(0, template)
        .filter((link) => link.trim() !== "").length;

      // console.log("Current Link Inputs:", linkInputs);
      console.log(providedLinksCount, template);
      // if (providedLinksCount < template) {
      //   setHighlightedIndexes(requiredIndexes);
      //   // setValidationError(`Please provide ${template} file links.`);
      //   message.error(`Please provide ${template} file links.`);
      //   setdispalProduct(false);
      // } else {
      setHighlightedIndexes([]);
      setValidationError("");
      CreateCollageUseLink(linkInputs);
      setloading(true);
      setdispalProduct(true);
      // console.log(linkInputs.filter(Boolean), "linkss");
      // }
    } else {
      message.error("Only 3 collages can be created. Your limit reached.");
    }
  };
  const CollageNotify = async () => {
    // console.log(data, "sidjshisil....");
    try {
      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: SelectUserData.fcm_token,
          title: "Skanda Stylz",
          body: "Your lookbook is ready ",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 201) {
        console.log(response.data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error signing in:", error);
      // throw error;
    }
  };
  const updateCollage = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),

        COLLAGE: "Stylist completed your outfit",
        SERVICE_ID: SelectUserData.SERVICE_ID.toString(),
        CUSTOMER_ID: SelectUserData.user_id.toString(),
        STYLIST_TOKEN: fcmToken,
        CUSTOMER_TOKEN: SelectUserData.fcm_token,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        notification.info({
          message: "Success",
          description: "I have sent the collage to the user.",
        });
        await CollageNotify();
        console.log(response.data, "kdsnknskdnk....out");
        setaxis(1);
        await getUseronlineCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };
  const collageImagesList = async () => {
    try {
      const params = {
        USER_ID: SelectUserData.user_id.toString(),
        STYLIST_ID: SelectUserData.stylist_id,
        USER_SERVICE_ID: SelectUserData.user_service_id,
      };

      const response = await axios.post(
        `${config.baseURL}collageImagesList`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response, "kdsnknskdnk....out");

      if (response?.data) {
        console.log(response.data, "kdsnknskdnk....out");
        await updateCollage();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  useEffect(() => {
    // getUserShoppingCollageData(SelectUserData?.user_id);
    getUserShoppingCollageData();
    handleTemplateSelection(5);
  }, [SelectUserData, loading]);

  // const sampleImages = [
  //   {
  //     brand: "Fusion",
  //     highPrice: "N/A",
  //     imageURL: "uploads\\output_-473Wx593H-443051074-black-MODEL.jpg",
  //     lowPrice: "N/A",
  //     name: "Women Embroidered Straight Midi Dress with Belt",
  //     price: "1499",
  //   },
  //   {
  //     brand: "Fusion",
  //     highPrice: "N/A",
  //     imageURL: "uploads\\output_-473Wx593H-443051074-black-MODEL.jpg",
  //     lowPrice: "N/A",
  //     name: "Women Embroidered Straight Midi Dress with Belt",
  //     price: "1499",
  //   },
  //   {
  //     brand: "Fusion",
  //     highPrice: "N/A",
  //     imageURL: "uploads\\output_-473Wx593H-443051074-black-MODEL.jpg",
  //     lowPrice: "N/A",
  //     name: "Women Embroidered Straight Midi Dress with Belt",
  //     price: "1499",
  //   },
  //   {
  //     brand: "Fusion",
  //     highPrice: "N/A",
  //     imageURL: "uploads\\output_-473Wx593H-443051074-black-MODEL.jpg",
  //     lowPrice: "N/A",
  //     name: "Women Embroidered Straight Midi Dress with Belt",
  //     price: "1499",
  //   },
  //   {
  //     brand: "Fusion",
  //     highPrice: "N/A",
  //     imageURL: "uploads\\output_-473Wx593H-443051074-black-MODEL.jpg",
  //     lowPrice: "N/A",
  //     name: "Women Embroidered Straight Midi Dress with Belt",
  //     price: "1499",
  //   },
  // ];
  const uploadCreatedCollage = async (file,DESCRIPTION,fileDataList,fileList) => {
    console.log(file, ";;;;;.......................");
    await UploadSingleColllage(file,DESCRIPTION,fileDataList,fileList);
  };
  const handleDelete = async (record) => {
    console.log(record, "kisikjdnjk");
    try {
      const body = {
        USER_ID: record.USER_ID.toString(),
        IMAGE_ID: record.IMAGE_ID.toString(),
      };
      const response = await axios.post(
        `${config.baseURL}deleteCollage`,
        body,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log(response);
      if (response?.status === 200) {
        await getUserShoppingCollageData();
      }

      return response.data;
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const SubmmitCollage = async () => {
    await collageImagesList();
  };
  const CreateCollageButton = () => {
    console.log(TemplateSelection, "TemplateSelection");
    handleTemplateSelection(TemplateSelection >= 5 ? TemplateSelection : 0);
  };
  const setuploadDragImageList = () => {};
  return (
    <div>
      {displaLinkData && axis === 0 ? (
        <>
          {UserShoppingCollageData.length <= 3 && (
            <>
              {/* {SelectUserData?.status === 4 ? (
                <></>
              ) : (
                <div className="Shopping-Collage-Header">
                  <h4 className="sideheadings">Select a template</h4>{" "}
                  <Flex horizontal className="Shopping-Collage-Header">
                   
                    <div className="Template-Options">
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(5)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreFilled />
                        </div>
                        <p>5 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(6)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>6 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(7)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>7 Images</p>
                      </Flex>
                      <Flex
                        vertical
                        align="center"
                        onClick={() => handleTemplateSelection(8)}
                      >
                        <div className="Scralink-Bottom-Template">
                          <AppstoreOutlined />
                        </div>
                        <p>8 Images</p>
                      </Flex>
                    </div>
                  </Flex>
                </div>
              )} */}
              {/* <div className="Shopping-Collage-Grid">
                {Array.from({ length: requiredLinks }).map((_, index) => (
                  <div
                    key={index}
                    className={`Shopping-Collage-Subcontent ${
                      highlightedIndexes.includes(index)
                        ? "error-container"
                        : ""
                    }`}
                  >
                    <LinkOutlined className="Scrapig-Linkoutlined" />
                    <Input
                      className="Shopping-Collage-Input"
                      type="url"
                      name={`Link${index + 1}`}
                      value={linkInputs[index]}
                      onChange={(e) => handleLinkChange(index, e)}
                      placeholder="Enter a link"
                    />
                  </div>
                ))}
              </div> */}
              {UserShoppingCollageData.length < 3 &&
                SelectUserData.status !== 4 && (
                  <Collage
                    sampleImages={product}
                    defaultFrameSize={requiredLinks ? requiredLinks : 5}
                    SelectUserData={SelectUserData}
                    handleSingleCollage={handleTemplateSelectionsubmmit}
                    uploadDragImageList={(data) => setuploadDragImageList(data)}
                    SendinputText={(data) => setCOLLAGE_DESCRIPTION(data)}
                    SendImageLinks={(data) => setproductimagelink(data)}
                    sendfileDataList={(data) => setsendfileDataList(data)}
                    uploadCreatedCollage={uploadCreatedCollage}
                  />
                )}{" "}
              {SelectUserData.status === 4 && (
                <>
                  <p style={{ textAlignLast: "center" }}>
                    User Service is Closed {UserShoppingCollageData}
                  </p>

                  {UserShoppingCollageData &&
                    UserShoppingCollageData.map((item, index) => (
                      <CollageComponent
                        key={index}
                        count={index + 1}
                        imageUrl={item?.IMAGE_NAME}
                        Delete={() => {
                          handleDelete(item);
                        }}
                        item={item}
                      />
                    ))}
                </>
              )}
              {/* {requiredLinks > 0 && (
                <div className="Shopping-Collage-Header">
                  <button
                    className="button"
                    onClick={() => {
                      handleTemplateSelectionsubmmit(requiredLinks);
                      setdispalycollagecretaion(true);
                    }}
                  >
                    Create Collage
                  </button>
                </div>
              )} */}
              {/* {validationError && (
                <div className="validation-error">
                  <p>{validationError}</p>
                </div>
              )} */}
              {/* <Flex vertical className="Shopping-Collage-Header">
                <div>
                  <p>Select a template</p>
                </div>
                <div className="Template-Options">
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(5)}
                  >
                    <div className="Scralink-Bottom-Template">
                      <AppstoreFilled />
                    </div>
                    <p>5 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(6)}
                  >
                    <div className="Scralink-Bottom-Template">
                      <AppstoreOutlined />
                    </div>
                    <p>6 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(7)}
                  >
                    <div className="Scralink-Bottom-Template">
                      <AppstoreOutlined />
                    </div>
                    <p>7 Images</p>
                  </Flex>
                </div>
              </Flex> */}
            </>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="shoppongCollageContainer">
        {/* UserShoppingCollageData.length < 3 &&  */}
        {/* {console.log(product,'product........')} */}
        {/*            */}
        {dispalProduct && (
          <>
            {" "}
            {loading && product ? (
              <div className="loading">
                <Spin tip="Loading..." style={{ marginTop: "5%" }} />
              </div>
            ) : (
              <>
                {" "}
                <Collage
                  sampleImages={product}
                  defaultFrameSize={requiredLinks ? requiredLinks : 5}
                  SelectUserData={SelectUserData}
                  handleSingleCollage={handleTemplateSelectionsubmmit}
                  uploadCreatedCollage={uploadCreatedCollage}
                  uploadDragImageList={(data) => setuploadDragImageList(data)}
                />
              </>
            )}
          </>
        )}
        {/* </>
        )} */}

        {/* {ImageUploadAndPreviewDisplay && (
        <>
          {" "}
          {ImageUploadAndPreviewloading ? (
            <div className="loading">
              <Spin tip="Loading..." style={{ marginTop: "5%" }} />
            </div>
          ) : (
            <ImageUploadAndPreview
              sampleImages={sampleImages}
              defaultFrameSize={5}
            />
          )}
        </>
      )} */}
        {/* {console.log(UserShoppingCollageData)} */}
        {UserShoppingCollageData.length > 0 && (
          <div className="userProfileMain" style={{ marginBottom: "10pxli" }}>
            <h1 className="heading"> Created Collage List</h1>
          </div>
        )}

        {axis === 0 ? (
          <Flex direction="row" className="Collagelist" wrap="nowrap">
            {UserShoppingCollageData &&
              UserShoppingCollageData.map((item, index) => (
                //   <div>
                //   <img
                //     key={item.IMAGE_NAME}
                //     src={`${config.imageUrl}${item.IMAGE_NAME}`}
                //     alt={item.IMAGE_NAME}
                //     className="collage-image"
                //   />
                //   <div className="CollageComponentimg">
                //   <p>{item.COLLAGE_DESCRIPTION}</p>
                // </div>
                // </div>
                <CollageComponent
                  key={index}
                  count={index + 1}
                  imageUrl={item?.IMAGE_NAME}
                  Delete={() => {
                    handleDelete(item);
                  }}
                  item={item}
                />
              ))}
          </Flex>
        ) : (
          <div className="onlineProduct">
            {UserShoppingCollageData.map((item, index) => (
              <CollageComponent
                key={index}
                count={index + 1}
                imageUrl={item?.IMAGE_NAME}
                // Delete={() => {
                //   handleDelete(item);
                // }}
                item={item}
              />
            ))}
          </div>
        )}
        {console.log(SelectUserData?.status === 4, SelectUserData?.status)}
        {SelectUserData?.status === 4 ? (
          <></>
        ) : (
          <>
            {axis === 0 && (
              <Flex className="Shopping-Collage-Bottom-button Shopping-Collage-Header">
                <Button
                  className="button"
                  disabled={UserShoppingCollageData.length !== 3}
                  onClick={
                    UserShoppingCollageData.length === 3 ? SubmmitCollage : null
                  }
                >
                  Upload Collage
                </Button>
              </Flex>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ShoppingCollage;
