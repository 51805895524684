import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import WardrobeCollage from "../Components/scrapinglinks/WardrobeCollage";
import ShoppingCollage from "../Components/scrapinglinks/ShoppingCollage";
const CollageBody = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [UserScrapinglinksData, setUserScrapinglinksData] = useState([]);

  const Type =
    SelectUserData?.service_category === "Online Shopping"
      ? "Shopping"
      : "Wardrobe";
  console.log(SelectUserData, "..........sdkbuadsbku", Type);
  const getUserScrapinglinksData = async (userId) => {
    try {
      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/SHOPPING_ASSISTANT`
      );

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;

        setUserScrapinglinksData(data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
     // throw error;
    }
  };
  useEffect(() => {
    // getUserScrapinglinksData(SelectUserData?.user_id);
  }, []);
  return (
    <div>
      {console.log(
        SelectUserData?.service_category,
        "lksndlknskln,SelectUserData?.service_category"
      )}
      {SelectUserData?.service_category === "Online Shopping" && (
        <ShoppingCollage SelectUserData={SelectUserData} />
      )}
      {SelectUserData?.service_category === "Digital Wardrobe" && (
        <WardrobeCollage SelectUserData={SelectUserData} />
      )}
    </div>
  );
};

export default CollageBody;
